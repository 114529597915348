import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Separator`}</h1>
    <p>{`To implement the Separator component into your project you’ll need to add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Separator from "@kiwicom/orbit-components/lib/Separator";
`}</code></pre>
    <p>{`After adding import to your project you can use it simply like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Separator />
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`The table below contains all types of props available in the Separator component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sideOffset`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#enum"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"none"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Amount of padding expressed as `}<a parentName="td" {...{
              "href": "https://orbit.kiwi/foundation/spacing/"
            }}>{`spacing tokens`}</a>{` in the opposite direction depending on the `}<inlineCode parentName="td">{`align`}</inlineCode>{` prop.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`align`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#enum"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"left"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The direction of indentation. If `}<inlineCode parentName="td">{`"center"`}</inlineCode>{` then it’s indented from both sides.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`spaceAfter`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`enum`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Additional `}<inlineCode parentName="td">{`margin-bottom`}</inlineCode>{` after component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#enum"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"solid"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The type of the separator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The color of the separator. The value should be a string with the value of a Tailwind border color class valid in Orbit. For instance: “border-blue-normal” or “border-product-normal”`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The label of the separator.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`enum`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`sideOffset`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`align`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`spaceAfter`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"none"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"left"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"solid"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"none"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"small"`}</inlineCode>{` - `}<strong parentName="td">{`deprecated (use `}<inlineCode parentName="strong">{`"300"`}</inlineCode>{`)`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"dashed"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"smallest"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"medium"`}</inlineCode>{` - `}<strong parentName="td">{`deprecated (use `}<inlineCode parentName="strong">{`"400"`}</inlineCode>{`)`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"center"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"dotted"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"small"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"large"`}</inlineCode>{` - `}<strong parentName="td">{`deprecated (use `}<inlineCode parentName="strong">{`"600"`}</inlineCode>{`)`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"double"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"normal"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"XLarge"`}</inlineCode>{` - `}<strong parentName="td">{`deprecated (use `}<inlineCode parentName="strong">{`"800"`}</inlineCode>{`)`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"none"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"medium"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"XXLarge"`}</inlineCode>{` - `}<strong parentName="td">{`deprecated (use`}<inlineCode parentName="strong">{`"1000"`}</inlineCode>{`)`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"large"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"300"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"largest"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"400"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"600"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"800"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"1000"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      