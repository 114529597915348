module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-118988244-1"]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/opt/buildhome/repo/docs/plugins/gatsby-remark-figma-images","id":"e7e8dfed-596a-59d2-a57d-469f1b959413","name":"@kiwicom/gatsby-remark-figma-images","version":"0.1.0","modulePath":"/opt/buildhome/repo/docs/plugins/gatsby-remark-figma-images/index.js","pluginOptions":{"plugins":[],"path":"/opt/buildhome/repo/docs/src/figma-images","format":"png","scale":2},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-images","id":"4b1cb48c-e84a-5d2b-9f59-6a6be24a6d32","name":"gatsby-remark-images","version":"7.13.1","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"linkImagesToOriginal":false,"maxWidth":745,"showCaptions":["title"]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-copy-linked-files","id":"ba892780-0bf5-59fa-8647-1566dba0e2b9","name":"gatsby-remark-copy-linked-files","version":"5.25.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[],"ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff","md","mdx"]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/buildhome/repo/node_modules/gatsby-remark-smartypants","id":"87513976-9b9f-52ec-915f-a98c4b055bf6","name":"gatsby-remark-smartypants","version":"6.14.0","modulePath":"/opt/buildhome/repo/node_modules/gatsby-remark-smartypants/index.js","pluginOptions":{"plugins":[],"backticks":false,"dashes":"oldschool"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"remarkPlugins":[null],"rehypePlugins":[],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo/docs","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"maxWidth":745,"showCaptions":["title"]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/orbit-glyph.svg","icons":[{"src":"src/images/orbit-icon-192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/orbit-icon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"865d26ebd28ba7c7cd00db1ac76f0ef8"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
